@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500&display=swap');
:root{
  --primary-color: #2687a4;
  --light-gray: #B0B0B0;
  --bg-cream: #EEE;
  --border-color: rgb(101, 49, 243);
  --danger-color: #CB3A31;
}

::-webkit-scrollbar{ width: 10px;}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb{
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

* {
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.body-page{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

li{
  list-style: none;
}

/* backgrounds */
.bg-cream{
  background-color: var(--bg-cream);
}

/* Headers */
.header-heavy{
  font-weight: 900;
}

.header-med{
  font-weight: 500;
  font-size: 30px;
  line-height: 43.2px;
  margin-bottom: 12px;
}

/* Inputs/Selects */
input,
select{
  width: 150px;
  height: 30px;
  font-size: 17px;
  margin: 5px 10px 5px 0;
}

.flex-row-wrap{
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;  
}

.approve-expence-btn{
  height: 15px;
  width: 15px;
}

.approved{
  color: green;
}

.denied{
  color: red;
}

.reject-font{
  font-size: 11px;
}

.table-font{
  font-size: 12px;
  grid-column: 1;
}

/* Tables */
.table{
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 1rem;
}

.tr{
  display: grid;
  /* grid-gap: 10px ; */
  border-bottom: 1px solid black;
  padding: 5px 0;
  cursor: pointer;
  width: 400px;
}

.expense-table{
  height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: hidden;
}

#search-sort-bar{
  margin-left: 15px;
}

.sort-select{
  font-size: 12px;
  width: 130px;
}

.expense-row.th{
  /* background-color: #2687a4; */
  width: 100%;
  margin-left: 0px;
}

.color1{
  background-color: red;
}

.color2{
  background-color: yellow;
}

.expense-row{
  width: 100%;
  grid-template-columns: 70px 3fr 60px 70px 2fr 2fr 1fr 50px 100px;
}

.reports-row{
  grid-template-columns: 70px 3fr 60px 70px 2fr 2fr 1fr 50px;
}

.expense-desc{
  min-width: 220px;
}

.prop-code-select{
  min-width: 100px;
}

@page{
  margin: 2rem 0 2rem 0;
}
@media print{
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
.expense-row-print{
  width: 750px !important;
  grid-template-columns: 60px 220px 40px 60px 100px 100px 50px 20px;
}

.th{
  font-weight: 900;
}

.td{
  font-size: 10px;
  /* margin-right: -10px; */
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.td > input{
  width: 90%;
}

.content-table{
  margin-top: 30px;
}


.table-height{
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;

}
.manage-card-holders-table>.tr{
  width: 550px;
  grid-template-columns: 2fr 3fr 1.5fr .8fr .5fr .5fr;
}

.manage-card-holders-table>div>.tr{
  width: 550px;
  grid-template-columns: 1.9fr 2.8fr 1.5fr .8fr .5fr .5fr;
}

.approvers-table{
  grid-template-columns: 230px 150px;
  max-width: 400px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.approvers-table>.tr{
  grid-template-columns: 230px 150px;
  width: 400px;
}

.approvers-table>.td{
  width: 250px;
}

.approvers-table>.tr>.td{
  width: 250px;
}

.properties-table{
  grid-template-columns: 230px 90px 30px;
  max-width: 400px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.properties-table>.tr{
  grid-template-columns: 230px 90px 30px;
  width: 400px;
}


.code-table{
  grid-template-columns: 130px 130px 150px;
  max-width: 400px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.code-table>.tr{
  grid-template-columns: 270px 60px 30px;
  width: 400px;
}

.code-table>.td{
  width: 150px;
}

.color{
  background-color: red;
}

.code-table>.tr>.td{
  width: 150px;
}

.approvee-list{

}

.allocation-table{
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 20px;
  grid-gap: 5px;
  margin-top: 1rem;
  margin-left: 0rem;
  margin-right: 0rem;
  width: 100%;
  position: relative;
}

.equal-allocation-spread{
  height: 15px;
  width: 43px;
}

.equal-spread{
  margin: 0 auto -10px;
}

.allo-trash{
  margin-top: 7px;
}

.allocate-section{
  border-top: 2px solid black;
}

.allocate-total{
  margin-left: 15px;
  width: 57px;
  text-align: right;
}

.allocation-start{
  grid-column: 4;
  text-align: end;
  padding-right: 10px;
}

.printing-allocation-start{
  grid-column: 1;
  text-align: end;
  padding-right: 10px;
}

.allocation-approve-start{
  grid-column: 4;
  
}

.allocation-approve-end{}

.allocation-text{
  font-size: 10px;
  min-height: 20px;
}

.top-allocation{
  border-top: 1px double black;
}

.allocation-approve{
  background-color: red;
}

.msg-lable{
  grid-column: 1;
  grid-row: 3;  
  text-align: right;
}

.hidden-lable{
  text-align: right;
  margin-right: 15px;
}

.new-user-row{
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.total-spand-row{
  padding-bottom: 2rem;
  padding-top: 2px;
  margin-top: 3px;
  border-top: 1px solid black;
}

.total-cat-row{
  font-weight: 700;
  font-size: 12px;
  padding-left: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid black;
}

.hidden-content{
  grid-column: 2 / span 4;
  border: 1px solid black;
  padding: 5px;
  
}

.approve-btn-box{
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.span-two{
  grid-column: 1 / span 2;
}

#add-receipt-input{
  width: 100%;
}

.image-tags{
  display: flex;
  flex-direction: column;
  /* grid-column: 8; */
  grid-row: span 4;
}

.image-tag{
  display: block;
  color: var(--primary-color);
}

.hidden-content-edit{
  grid-column: 2 / span 6;
  border: 1px solid black;
  padding: 5px;
  
}

.calendar-grid{
  grid-row: 1 / span 2;
}

/* Buttons */
.btn{
  background-color: #EEE;
  color: black;
  border-radius: 6px;
  padding: 10px 30px;
  margin-right: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  
}

.buttons-row{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.btn-primary{
  background-color: var(--primary-color);
  padding: 10px;
}

.print-btn{
  margin-top: 5px;
  padding: 2px 10px !important;
  width: 100px;
  max-height: 30px;
}

.btn-approve{
  background-color: green;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 12px;
}

.mass-approve{
  height: 25px;
  margin-top: 8px;
  width: 90px;
}

.btn-danger{
  background-color: var(--danger-color);
  color: #fff;
}

.delete-expense-container{
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: end;
}

.delete-expense-container.trash-it{
  background-color: green;
}

.btn-note{
  padding: 8px 15px;
}

.btn-icon{
  padding: 0;
}

.table-btn{
  height: 30px;
  width: 30px;
}

.btn:hover{
  border: 2px solid var(--border-color);
}

.btn-selected{
  background-color: #DAFFDC
}

.btn-mid-width{
  width: 218px;
}

.btn-quarter-width{
  width: 25%;
}

.btn-half-width{
  width: 50%;
}

.btn-3q-width{
  width: 75%;
}

.btn-full-width{
  width: 100%;
}

.btn-bottom{
  position: absolute;
  bottom: 7%;
}

.btn-icon-left{
  margin-right: 10px;
  font-size: 10.5px;
}

.add-allo-btn:hover{
  color: var(--border-color);
}

/* Inputs */
.underline-input{
  outline: none;
  border: none;
  border-bottom: 2px solid var(--light-gray);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 5px;
  background-color: transparent;
}

.underline-input:focus{
  border-bottom: 2px solid var(--border-color);
  
}

.input-box{
  background-color: var(--primary-color);
  width: 500px;
}

.width-full{
  width: 100%;
}

/* Text */
.text-dark{
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
  text-align: center;
  margin: 0;
}

.text-light{
  font-weight: 400;
  font-size: 16px;
  line-height: 25.2px;
  color: var(--light-gray);
  text-align: center;
  margin: 0;
}

.light-text{
  color: var(--light-gray);
}

.font-bold{
  font-weight: bold;
}

/* Icons */
.icon-padding-right{
  padding-right: 10px;
}

.circle-icon{
  background-color: #EEE;
  padding: 20px;
  border-radius: 50%;
}

.green{
  background-color: var(--primary-color);
  
}

/* Margins */
.margin-top-small{
  margin-top: 1rem;
}

.margin-top-med{
  margin-top: 2rem;
}

.margin-top-large{
  margin-top: 3rem;
}

.margin-top-xl{
  margin-top: 4rem;
}

.margin-bottom-small{
  margin-bottom: 1rem;
}

.margin-bottom-med{
  margin-bottom: 2rem;
}

.margin-bottom-large{
  margin-bottom: 3rem;
}

.margin-bottom-xl{
  margin-bottom: 4rem;
}

.margin-left-small{
  margin-left: 1rem;
}

.margin-left-med{
  margin-left: 2rem;
}

.margin-left-large{
  margin-left: 3rem;
}

.margin-left-xl{
  margin-left: 4rem;
}

.margin-right-small{
  margin-right: 1rem;
}

.margin-right-med{
  margin-right: 2rem;
}

.margin-right-large{
  margin-right: 3rem;
}

.margin-right-xl{
  margin-right: 4rem;
}

.neg-left-margin-lg{
  margin-left: -22px;
}

/* Card */
.card{
  background-color: var(--bg-cream);
  border: 1px solid black;
  border-radius: 10px;
  padding: 2rem;
}

.login-card{
  min-height: 500px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dashboard Pages */
.dashboard-page{
  display: flex;
  flex-direction: column;
}


/* Navbar */
.navbar{
  position: relative;
  padding: 40px 20px;
  background-color: #0A0A0A;
  width: 250px;
  height: calc(100vh - 50px);
  z-index: 2;
}

.nav-header{
  margin-bottom: 2rem;
}

.nav-logo{
  background-color: var(--primary-color);
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

.navbar-link{
  color: #6E6E6E;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 28px 0;
  text-decoration: none;
  cursor: pointer;
}

.navbar-link:hover{
  text-decoration: underline var(--border-color) 2px;
}

.nav-link-logo{
  margin-right: 10px;
}

.active-nav-link{
  color: #FFF;
}

.nav-body{
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-user-profile{
  display: flex;
  flex-direction: row;
}

.user-img-circle{
  overflow: hidden;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.img-small{
  height: 30px;
  width: 30px;
  border: 3px solid #FFF;
}

.user-img-circle img{
  max-width: 100%;
  max-height: 100%;
}

.nav-user-profile-header{
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  color: #FFF;
}

.nav-user-info{
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.nav-user-job-title{
  text-align: start;
}


.navbar > ul{
  margin-top: 0;
  background-color: black;
  
}

/* Dashboard Header */
.dashboard-header{
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  
}

.dashboard-header > h1 {
  margin: 0;
  Padding: 0 2rem;
  font-weight: 500;
}

.dashboard-header > ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.dashboard-header > ul > li{
  padding: 5px 10px;
  border-bottom: 2px solid transparent;
}

.nav-link{
  text-decoration: none;
  color: black;
  font-weight: 500;
 }

 .active-header-link{
  border-bottom: 2px solid var(--border-color);
 }

 /* Dashboard Body */
 .dashboard-body{
   height: calc(100vh - 50px);
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 0 0 0 1rem;
 }

 .row{
   flex-direction: row;
   align-items: flex-start;
   justify-content: start;
 }

 .dashboard-body-navbar{
   padding: 0;
 }

 .body-content{
   width: 100%;
   display: flex;
   flex-direction: column;
  }
  
  .dashboard-body-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .search-sort{
   flex-direction: row;
 }

 /* Profile */
 .profile-header{
   margin: 3rem 0;
 }

 .profile-body{
   display: flex;
   flex-direction: column;
 }

 .data-point{
   display: flex;
   flex-direction: row;
   margin: 5px 0;
   width: 100%;
   font-size: 14px;
 }

 .data-column{
   flex-direction: column;
 }

 .profile-label{
  display: inline-block;
  font-weight: 700;
  width: 70px;
  text-align: left;
  margin-right: 1rem;
 }

 .profile-label-width{
  width: 140px;
 }


 .card-num{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 5px;
   width: 80px;
 }

 .add-btn{
   font-size: 20px;
   margin-left: 1rem;
 }

 .properties-add{
   padding-right: 5px;
 }

 .add-property{
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 .property{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .approvee-list{
   width: 180px;
   margin-block-start: 5px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .trash-it{
   margin-left: 1rem;
 }

.trash-it:hover{
  cursor: pointer;
  color: red;
}

.approve-it:hover{
  color: var(--primary-color);
}

 .reset-password{

}

.drop-zone{
  max-width: 100%;
  height: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  border: 4px dashed var(--light-gray);
  border-radius: 10px;
}

.drop-zone--over{
  border: 4px solid var(--primary-color);
}

.drop-zone__input{
  display: none;
}

.drop-zone__prompt{
  color: #aaa;
}

.drop-zone__thump{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ddd;
  background-size: cover;
  position: relative;
}

.drop-zone__thump-title{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: rgba(0,0,0,0.5);
  font-size: 14px;
  text-align: center;
}

/* Reports */
.reports-body{
  justify-content: space-between;
}

.reports-header{
  width: 100%;
  max-width: 97%;
}

.report-settings{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reports-content{
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  max-width: 98%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dashboard-h1{
  text-align: center;
}

.data-point-select{
  font-size: 11px;
  height: 20px;
  margin: 0;
}

/* GL Codes */
.gl-code-row{
  display: grid;
  grid-template-columns: 281px 70px 30px;
  height: 30px;
  border-bottom: 1px solid black;
  padding: 0 5px 0 0;
  cursor: pointer;
}

.gl-code-row:hover{
  background-color: rgba(71, 255, 84, .4);
}

.gl-text{
  font-size: 12px;
  
}

.gl-edit{
  margin-top: -5px;
}

/* Approve */
.approve-expense-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

}

.approve-row-header{
}

.approve-row{
  width: 900px;
  grid-template-columns: .8fr 1.2fr 3fr 1.5fr 1.5fr .5fr .5fr 1fr;
}

.expensed-by{
  grid-column: 2 / span 1;
}

.approve-message{
  grid-column: 3 / span 6;
}

/* Imports */
.import-box{
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 2px dashed var(--light-gray);
}

.import-success{
  margin: 1rem 0 3rem 0;
  font-weight: 600;
  font-size: 20px;
}

.imports-row{
  margin: 10px 0;
  display: grid;
  grid-template-columns: .7fr 3fr .8fr .7fr .8fr;
}

.imports-row>.td{
  font-size: 14px;
}

.amount-number{
  text-align: right;
  padding-right: 30px;
}

.imports-row-header{
  width: 96%;
}

.imports-row-header>.td{
  font-weight: bold;
}

.spinner-box{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Other */
.flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;  
  height: 100vh;
  width: 100vw;

}

.overlay{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  height: 100vh;
  width: 100vw;
}

.details-card{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  width: 300px;
  border-radius: 20px;
  padding: 1rem;
  margin-top: 5rem;
}

.add-card{
  justify-content: flex-end;
}

.edit-details{
  height: 15px;
  width: calc(100% - 100px);
  font-size: 13px;
  border: none;
  border-bottom: 2px solid var(--light-gray);
  outline: none;
  margin: 0;
}

.edit-details-checkbox{
  background-color: red;
  width: 20px;
}

.edit-details:focus{
  border-bottom: 2px solid var(--border-color);
}

.table-edit{
  width: 100%;
  margin-top: 5px;
  height: 20px;
  font-size: 12px;
}

.close-btn{
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.details-card h3{
  text-align: center;
}

.profile-card{
  display: flex;
  flex-direction: column;
}

.profile-data{
  display: flex;
  align-items: flex-start;
}

.hamberger-icon{
  position: fixed;
  left: 1rem;
  top: 6rem;
  cursor: pointer;
}

.nav-close{
  color: white;
  position: absolute;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.expense-popup{
  width: 400px;
}

.edit-textarea{
  width: calc(100% - 100px);
  height: 150px;
}

.add-file{
  cursor: pointer;
  font-size: 30px;
}

/* Spending Limit */
.limit-row{
  display: grid;
  grid-template-columns: 1.5fr .5fr .2fr;
}

.expense-content{
  margin-right: 52px;
}

.spinner-styles{
  height: 50px;
  width: 50px;
}

.error-text{
  color: red;
}

.search-bar-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.search-icon{
  position: absolute;
  left: 6px;
  top: 16px;  
}

.search-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-print-btn{
  margin-left: 32px;
  margin-top: 8px;
}

.search-input{
  padding-left: 24px;
  width: 400px;
  border-radius: 8px;
  height: 40px;
  margin-right: 0;
}

.search-table-container{
  background-color: blue;
  width: 100%;
}

.search-table-date{
  background-color: green;
  width: 100px;
}

.search-table{
  background-color: red;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

@media(max-width: 1000px){
  .dashboard-header{
    flex-direction: column;
    height: 80px;
  }
  
  .dashboard-header > h1{
    font-size: 24px;
    padding: 0;
  }

  .tr{
    width: 500px;
  }

  .expense-table{
    height: calc(100vh - 380px);
  }
  
  .expense-row{
    width: 100%;
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
    grid-template-areas: 
    'date description description approveddenied awaitings'
    'images property code limit awaitings'
    'images spender message amount awaitings'
    'images spender message ccnumber awaitings'
    ;
  }

  .reports-row{
    width: 100%;
    grid-template-columns: 1fr 2fr 2.5fr 0.5fr;
    grid-template-areas: 
    'date description description approveddenied'
    'images property code limit'
    'images spender message amount'
    'images spender message ccnumber'
    ;
  }

  
.date{
  grid-area: date;
}

.description{
  grid-area: description;
}

.description{
  grid-area: description;
}

.ccnumber{
  grid-area: ccnumber;
}

.amount{
  grid-area: amount;
  text-align: left;
}

.property{
  grid-area: property;
}

.code{
  grid-area: code;
}

.limit{
  grid-area: limit;
}

.approveddenied{
  grid-area: approveddenied;
}

.awaitings{
  grid-area: awaitings;
}

.images{
  grid-area: images;
}

.spender{
  grid-area: spender;
}

.message{
  grid-area: message;
}

  .approve-row{
    width: 600px;
  }

}

@media(max-width: 600px){
  
  .manage-card-holders-table{
    width: 350px;
  }

  .dashboard-body{
    padding: 0 -1rem 0 0;
  }

  .expense-table{
    height: calc(100vh - 400px);
  }
    
.expense-row{
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas: 
    'sdate sdescription sdescription'
    'samount sproperty slimit'
    'sccnumber scode scode'
    'simages smessage smessage'
    'simages sspender sawaitings'
    'simages sapproveddenied sapproveddenied';
}
    
.reports-row{
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas: 
    'sdate sdescription sdescription'
    'samount sproperty slimit'
    'sccnumber scode scode'
    'simages smessage smessage'
    'simages sspender sawaitings'
}
  
.date{
  grid-area: sdate;
}

.description{
  grid-area: sdescription;
}

.ccnumber{
  grid-area: sccnumber;
}

.amount{
  grid-area: samount;
  text-align: left;
}

.property{
  grid-area: sproperty;
}

.code{
  grid-area: scode;
}

.limit{
  grid-area: slimit;
}

.approveddenied{
  grid-area: sapproveddenied;
}

.awaitings{
  grid-area: sawaitings;
}

.images{
  grid-area: simages;
}

.spender{
  grid-area: sspender;
}

.message{
  grid-area: smessage;
}

  .navbar{
    display: none;
    position: absolute;
  }

  .nav-close{
    display: block;
  }

  .dashboard-h1{
    font-size: 23px;    
  }  
  
  .approve-row{
    width: 350px;
    grid-template-columns: .7fr 1.5fr 1.5fr 1.5fr 1.5fr .5fr .5fr;
  }

}